import {
    CheckoutPaymentsComponent as SourceCheckoutPaymentsComponent,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';
import CheckoutPayment from 'Component/CheckoutPayment';
import { HYPERPAY_APPLEPAY, HYPERPAY_CARDS, HYPERPAY_MADA, TAMARA_PAYMENT, TABBY_PAYMENT } from 'Route/OnepageCheckout/OnepageCheckout.component';
import './CheckoutPayments.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutPayments/Component */
export class CheckoutPaymentsComponent extends SourceCheckoutPaymentsComponent {
    // TODO implement logic

    renderPayment(method) {
        const {
            selectPaymentMethod,
            selectedPaymentCode,
            totals,
            email,
            currentDeliveryAddress,
            isTabbyDisabled,
            tabbyErrorMessage,
        } = this.props;

        let isDisabled = false;
        let paymentMethodErrorMsg = '';

        const { code } = method;
        const isSelected = selectedPaymentCode === code;

        let countryPhone = currentDeliveryAddress.telephone || "";
        let countryCode = countryPhone.substring(0,3);

        if (currentDeliveryAddress.country_id == 'SA' && countryCode != '966') {
            currentDeliveryAddress.telephone = "966" + countryPhone;
        }

        // console.log("CheckoutPayments this.props: ", this.props);
        if (code == TABBY_PAYMENT && (email == '' || email == null || currentDeliveryAddress.telephone.length != 12)) {
            return null;
        }

        if (code == TABBY_PAYMENT && isTabbyDisabled) {
            isDisabled = true;
            // console.log("CheckoutPayments isDisabled: ", isDisabled);
            // paymentMethodErrorMsg = 'This method is not available to you!';
        }

        return (
            <CheckoutPayment
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
              selectedPaymentCode={ selectedPaymentCode }
              totals={ totals }
              currentDeliveryAddress={ currentDeliveryAddress }
              email={ email }
              isDisabled={ isDisabled }
              isTabbyDisabled={ isTabbyDisabled }
              tabbyErrorMessage={ tabbyErrorMessage }
            />
        );
    }
}

export default CheckoutPaymentsComponent;
