/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { hydrate, render } from 'react-dom';

import App from 'Component/App';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import 'Style/main';

// let's register service-worker
waitForPriorityLoad().then(
    /** @namespace Render/waitForPriorityLoad/then */
    () => {
        if ('serviceWorker' in navigator) {
            const swUrl = '/service-worker.js';
            // const webEngageUrl = 'https://widgets.ksa.webengage.com/js/service-worker.js';
            navigator.serviceWorker.register(swUrl, { scope: '/' });
            // navigator.serviceWorker.register(webEngageUrl);
        }

        if (window.metaHtml) {
            const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
            Object.values(doc?.head?.children || {}).forEach((node) => document.head.appendChild(node));
        }
    },
);

// eslint-disable-next-line no-undef
// importScripts('https://widgets.ksa.webengage.com/js/service-worker.js');

// Code bellow enables the hot reloading of plugins
// Why? I have no idea. Further debugging needed.
// TODO: understand why this helps HMR
if (module.hot) {
    module.hot.accept();
}

function HotApp() {
    return <App />;
}

// render(<HotApp />, document.getElementById('root'));

// Sanyasirao changes
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<HotApp />, rootElement);
} else {
  render(<HotApp />, rootElement);
}
